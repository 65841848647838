const operationTypeValues = [
    {
        label: "Conta e Ordem",
        value: "CONTA_E_ORDEM",
    },
    {
        label: "Encomenda",
        value: "ENCOMENDA",
    },
    {
        label: "Gestão",
        value: "GESTAO",
    }
];

export default operationTypeValues;